import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './pcc.css';
import { useLocation } from 'react-router-dom';

// Create a styled component


function Pcc() {

    const [programs, setPrograms] = useState([]);
    const [courses, setCourses] = useState([]);
    const [curricula, setCurricula] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [newCurriculum, setNewCurriculum] = useState('');


    /*const showComponent = (componentId) => {
        const components = document.querySelectorAll('.component');
        components.forEach((component) => {
            component.style.display = 'none';
        });

        const selectedComponent = document.getElementById(componentId);
        selectedComponent.style.display = 'block';
    };*/

  

  const fetchPrograms = async () => {
    try {
        const response = await axios.get('https://ssb1531.uta.cloud/backend/pcc.php'); // Replace with your API endpoint
      setPrograms(response.data.data.data);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const fetchCourses = async (programId) => {
    try {
        const response = await axios.get(`https://ssb1531.uta.cloud/backend/program.php?programId=${programId}`); // Replace with your API endpoint
        setCourses(response.data.data.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchCurricula = async (courseId) => {
      try {
          setSelectedCourse(courseId)
          const response = await axios.get(`https://ssb1531.uta.cloud/backend/course.php?courseId=${courseId}`); // Replace with your API endpoint
        setCurricula(response.data.data.data);
    } catch (error) {
      console.error('Error fetching curricula:', error);
    }
  };

    const addCurriculum = async (event) => {
        let newCurriculum = event.target.value;
    if (selectedCourse && newCurriculum) {
      try {
          await axios.post('https://ssb1531.uta.cloud/backend/curriculum.php', {
          courseId: selectedCourse,
          curriculumName: newCurriculum,
        }); // Replace with your API endpoint
        fetchCurricula(selectedCourse); // Refresh curriculum list
        setNewCurriculum('');
      } catch (error) {
        console.error('Error adding curriculum:', error);
      }
    }
  };

  const deleteCurriculum = async (curriculumId) => {
    try {
        await axios.delete(`https://ssb1531.uta.cloud/backend/curriculum.php?curriculumId=${curriculumId}`); // Replace with your API endpoint
      fetchCurricula(selectedCourse); // Refresh curriculum list
    } catch (error) {
      console.error('Error deleting curriculum:', error);
    }
  };

  const editCurriculum = async (curriculumId, newCurriculumName) => {
    try {
        await axios.put(`https://ssb1531.uta.cloud/backend/curriculum.php?curriculumId=${curriculumId}`, {
            newName: newCurriculumName,
            course: selectedCourse,
      }); // Replace with your API endpoint
      fetchCurricula(selectedCourse); // Refresh curriculum list
    } catch (error) {
      console.error('Error editing curriculum:', error);
    }
  };
  useEffect(() => {
    // Fetch programs and courses on component mount
    fetchPrograms();
  }, []);

    return (
        <div>
            <div className="admin-dashboard">
                <div className="topnav">
                    <button className="toggle-sidebar-button" >&#9776;</button>
                    <div className="logo-container">
                        <img src="images/logo.png" className="logo" alt="Logo" />
                        <span className="site-name">Edumetrics</span>
                    </div>
                    <div className="dropdown">
                        <a href="#" className="burger-icon">
                            &#9776;
                        </a>
                        <div className="dropdown-content">
                            <a href="/home">
                                <img src="icons/icons8-home-50.png" /> Home
                            </a>
                            <a href="/services">
                                <img src="icons/icons8-services-50.png" /> Services
                            </a>
                            <a href="/contact_us">
                                <img src="icons/icons8-contact-us-50.png" /> Contact Us
                            </a>
                            <a href="/aboutus">
                                <img src="icons/icons8-about-us-50.png" /> About Us
                            </a>
                            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                <img src="icons/blog.png" />
                                Our Blog
                            </a>
                            <a href="/login">
                                <img src="icons/exit.png" /> Log Out
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container">
                    

                    {/* Render the list of programs */}
                    <select onChange={(e) => fetchCourses(e.target.value)}>
                        {programs.map((program) => (
                            <option key={program.program_id} value={program.program_id}>
                                {program.program_name}
                            </option>
                        ))}
                    </select>

                    {/* Render the list of courses */}
                    <select onChange={(e) => fetchCurricula(e.target.value)}>
                        {courses.map((course) => (
                            <option key={course.course_id} value={course.course_id}>
                                {course.course_name}
                            </option>
                        ))}
                    </select>

                    {/* Render the list of curricula */}
                    <ul>
                        {curricula.map((curriculum) => (
                            <li key={curriculum.component_id}>
                                {curriculum.component_name}
                                <button onClick={() => deleteCurriculum(curriculum.component_id)}>Delete</button>
                                <button
                                    onClick={() => {
                                        const newName = prompt('Enter new curriculum name:');
                                        if (newName) {
                                            editCurriculum(curriculum.component_id, newName);
                                        }
                                    }}
                                >
                                    Edit
                                </button>
                            </li>
                        ))}
                    </ul>

                    {/* Add new curriculum */}
                    <div>
                        <input
                            type="text"
                            placeholder="New Curriculum Name"
                            value={newCurriculum}
                            onChange={(e) => setNewCurriculum(e.target.value)}
                        />
                        <button onClick={addCurriculum}>Add Curriculum</button>
                    </div>

                        
                </div>

                <div className="footer">
                    <img src="images/uta_logo.png" alt="UTA" className="uta_logo" />
                    <div className="uni_info">
                        <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                        <p>701 S Nedderman Dr,</p>
                        <p>Arlington, TX 76019</p>
                        <p>817-090-xxxx</p>
                        <p>Privacy Statement | Contact</p>
                        <p>@2023 University of Texas at Arlington</p>
                    </div>
                    <div className="contact-us">
                        <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                        <img src="images/footer/email.png" alt="mail" />
                        <img src="images/footer/linkedin.png" alt="linkedin" />
                        <img src="images/footer/twitter (1).png" alt="twitter" />
                        <img src="images/footer/instagram.png" alt="instagram" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pcc;
